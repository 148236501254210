import React from "react"

const PageNotFound = () => {

  return (
    <div>
      Page not found
    </div>
  )
}



export default PageNotFound
 